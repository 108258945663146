<template>
  <div>
    <portal to="subheader_actions">
      <b-button
        v-b-modal.modal-form-equivalent-unit
        @click="exportarAction"
        class="mr-2"
      >
        <i class="flaticon-download icon-nm"></i>
        Exportar
      </b-button>
    </portal>

    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text class="box" >
            <div class="col " >
              <div class="row mb-3 buscador">
                <div class="col pr-0" >
                  <b-form-input
                    id="filter-input"
                    v-model="lupa"
                    type="search"
                    placeholder="Escriba para buscar"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div :style="'overflow-x: auto;'">
              <b-table
                :filter="lupa"
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(status)="data">
                  {{ calculateStatus(data.item.is_active) }}
                </template>

              </b-table>

            </div>

          </b-card-text>

          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
          
        </b-card>
      </div>
    </div>
   
  </div>

</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service"; 

export default {
  data() {
    return {
      showModal: false,
      loaded: false,
      currentObject: {},
      comboUnidadesMedida:[],
      currentPage: 1,
      perPage: 12,
      items: [],
      lupa: '',
      fields: [
        {
          key: "family_description",
          sortable: true,
          label: "Familia"
        },
        {
          key: "type",
          sortable: true,
          label: "Tipo"
        },
        {
          key: "measure_unit_description",
          sortable: true,
          label: "Unidad"
        },
        {
          key: "spec_tech_description",
          sortable: true,
          label: "Espeficicación sobre el material"
        },
        {
          key: "navasoft_code",
          sortable: true,
          label: "NAVASOFT Código"
        },
        {
          key: "navasoft_measure_unit",
          sortable: true,
          label: "NAVASOFT U.M. "
        },
        {
          key: "navasoft_description",
          sortable: true,
          label: "NAVASOFT Descripción"
        },
        {
          key: "description",
          sortable: true,
          label: "Descripción"
        },
        {
          key: "provider_description",
          sortable: true,
          label: "Proveedor"
        },
        {
          key: "brand_description",
          sortable: true,
          label: "Marca"
        },
        {
          key: "fecha",
          sortable: true,
          label: "TIPO DE CAMBIO Fecha"
        },
        {
          key: "CostoSoles",
          sortable: true,
          label: "TIPO DE CAMBIO Costo Soles"
        },
        {
          key: "CostoDolares",
          sortable: true,
          label: "Transporte"
        },
        {
          key: "peso",
          sortable: true,
          label: "PESO"
        },
        {
          key: "area",
          sortable: true,
          label: "ÁREA"
        },
        {
          key: "status",
          sortable: true,
          label: "Estado"
        }
      ]
    };
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },

  methods: {

    calculateStatus(status) {
      return status === true ? "ACTIVO" : "INACTIVO";
    },

    listItems() {
      ApiService.get("api/item", "").then(({ data }) => {
        console.log("items=>",data);
        this.items = data;
      });
    },
    
    exportarAction() {
      let url = `${process.env.VUE_APP_API_URL}`;
      ApiService.get("api/report/master", "").then(({ data }) => {
        let urlCompleta = `${url}${data.URL}`;
        window.open(urlCompleta);
      });
    }
    
  },

  created() {
    this.listItems();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Maestros" },
      { title: "Maestro de Materiales" }
    ]);
  },

  components: {
    
  }

};

</script>

<style>

.buscador{
  float: right;
  align-items: flex-end;
  justify-content: right;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

</style>